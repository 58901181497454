import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "content",
    "filterInput",
    "item",
    "nativeSelect",
    "toggleButton",
    "toggleButtonText",
    "clearButton",
    "caret",
  ];

  connect() {
    this.placeholder =
      this.element.dataset.placeholder || "Select an option...";
    this.selectedItemIndex = null; // Track the selected item
    this.focusedItemIndex = null; // Start with no focus
    this.isDropdownOpen = false;

    // Set initial placeholder text for the faux input
    this.toggleButtonTextTarget.textContent = this.placeholder;

    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  toggle() {
    if (this.isOpen()) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  isOpen() {
    return this.isDropdownOpen;
  }

  openDropdown() {
    this.isDropdownOpen = true;
    this.contentTarget.style.display = "block";
    this.filterInputTarget.focus();

    // Update aria-expanded for accessibility
    this.toggleButtonTarget.setAttribute("aria-expanded", "true");

    // Focus on the selected item or the first item if none is selected
    this.focusedItemIndex =
      this.selectedItemIndex !== null ? this.selectedItemIndex : 0;
    this.highlightItem(this.itemTargets[this.focusedItemIndex]);
  }

  closeDropdown() {
    this.isDropdownOpen = false;
    this.contentTarget.style.display = "none";

    // Update aria-expanded to false when the dropdown is closed
    this.toggleButtonTarget.setAttribute("aria-expanded", "false");
  }

  filter() {
    const query = this.filterInputTarget.value.toLowerCase();
    this.itemTargets.forEach((item) => {
      const text = item.dataset.optionText.toLowerCase();
      item.style.display = text.includes(query) ? "block" : "none";
    });

    // Set focus to the first visible item after filtering
    const visibleItems = this.itemTargets.filter(
      (item) => item.style.display !== "none",
    );
    this.focusedItemIndex = visibleItems.length > 0 ? 0 : null;
    if (this.focusedItemIndex !== null) {
      this.highlightItem(visibleItems[this.focusedItemIndex]);
    }
  }

  selectItem(event) {
    const selectedItem = event.currentTarget;
    const value = selectedItem.dataset.optionValue;
    const text = selectedItem.dataset.optionText;

    this.highlightItem(selectedItem); // Highlight the selected item
    this.selectedItemIndex = this.itemTargets.indexOf(selectedItem); // Track the selected item

    // Update the hidden select element with the selected value
    this.nativeSelectTarget.value = value;

    // Update the button text to reflect the selected value
    this.toggleButtonTextTarget.textContent = text;

    // Show the clear button and hide the caret
    this.clearButtonTarget.style.display = "inline";
    this.caretTarget.style.display = "none";

    // Close the dropdown after selection
    this.closeDropdown();

    // Set focus back to the faux input (button)
    this.toggleButtonTarget.focus();
  }

  highlightItem(item) {
    this.itemTargets.forEach((item) =>
      item.setAttribute("data-highlighted", "false"),
    );
    if (item) {
      item.setAttribute("data-highlighted", "true");
    }
  }

  handleKeydown(event) {
    const visibleItems = this.itemTargets.filter(
      (item) => item.style.display !== "none",
    );
    const query = this.filterInputTarget.value.toLowerCase();

    switch (event.key) {
      case "ArrowDown":
        this.focusedItemIndex =
          (this.focusedItemIndex + 1) % visibleItems.length;
        this.highlightItem(visibleItems[this.focusedItemIndex]);
        break;
      case "ArrowUp":
        this.focusedItemIndex =
          (this.focusedItemIndex - 1 + visibleItems.length) %
          visibleItems.length;
        this.highlightItem(visibleItems[this.focusedItemIndex]);
        break;
      case "Enter":
        // First, try to find an exact match
        const exactMatch = visibleItems.find(
          (item) => item.dataset.optionText.toLowerCase() === query,
        );
        if (exactMatch) {
          this.selectItem({ currentTarget: exactMatch });
        } else {
          // If no exact match, select the currently focused item
          if (visibleItems.length > 0) {
            this.selectItem({
              currentTarget: visibleItems[this.focusedItemIndex],
            });
          }
        }
        break;
      default:
        return;
    }

    event.preventDefault(); // Prevent default behavior for arrow keys and Enter
  }

  handleClickOutside(event) {
    if (!this.element.contains(event.target)) {
      this.closeDropdown();
    }
  }

  clearSelection(event) {
    // Prevent the dropdown from toggling
    event.stopPropagation();

    // Reset the hidden select value
    this.nativeSelectTarget.value = "";

    // Reset the button text to the placeholder
    this.toggleButtonTextTarget.textContent = this.placeholder;

    // Hide the clear button
    this.clearButtonTarget.style.display = "none";

    // Show the caret
    this.caretTarget.style.display = "block";

    // Clear the filter input field
    this.filterInputTarget.value = "";

    // Reset the selected item index
    this.selectedItemIndex = null;

    // Set focus back to the faux input (button)
    this.toggleButtonTarget.focus();

    // Reset visibility of all items (show all items)
    this.itemTargets.forEach((item) => {
      item.style.display = "block";
    });
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "text"];

  connect() {
    this.textTarget.textContent = "Hello World!";
  }

  handleClick() {
    this.textTarget.style.display = "block";
  }
}
